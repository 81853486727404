import React from 'react';
import { 
  Dice6, Calculator, Search, Clock,
  Compass, Users, Coins, BookOpen 
} from 'lucide-react';

const tools = [
  { icon: Dice6, label: 'Roll', color: 'amber' },
  { icon: Calculator, label: 'Calc', color: 'emerald' },
  { icon: Search, label: 'Rules', color: 'blue' },
  { icon: Clock, label: 'Timer', color: 'purple' },
  { icon: Compass, label: 'Weather', color: 'sky' },
  { icon: Users, label: 'NPCs', color: 'rose' },
  { icon: Coins, label: 'Loot', color: 'yellow' },
  { icon: BookOpen, label: 'Tables', color: 'indigo' }
];

export function QuickTools() {
  return (
    <div className="grid grid-cols-4 gap-1 h-full">
      {tools.map(({ icon: Icon, label, color }) => (
        <button
          key={label}
          className="bg-stone-800/30 border border-stone-700 rounded flex flex-col items-center justify-center p-1
            hover:bg-stone-700/50 transition-all duration-200"
        >
          <Icon className={`w-4 h-4 mb-0.5 text-${color}-400`} />
          <span className="text-[10px] font-medium text-stone-300">{label}</span>
        </button>
      ))}
    </div>
  );
}
import React, { useRef, useState, useEffect } from 'react';
import { ArrowDownRight, GripVertical } from 'lucide-react';
import { WidgetData, WidgetPosition, WidgetSize } from '../types/widget';
import { CombatTracker } from './widgets/CombatTracker';
import { DiceRoller } from './widgets/DiceRoller';
import { MusicControls } from './widgets/MusicControls';
import { NotesContent } from './widgets/NotesContent';
import { QuickTools } from './widgets/QuickTools';
import { SoundEffects } from './widgets/SoundEffects';
import { MiniMap } from './widgets/MiniMap';
import { MonsterDetails } from './widgets/MonsterDetails';

interface WidgetProps extends WidgetData {
  cellSize: number;
  maxSize: { width: number; height: number };
  onResize: (id: string, newSize: WidgetSize) => void;
  onMove: (id: string, newPosition: WidgetPosition) => void;
}

const widgetComponents: Record<string, React.FC> = {
  '1': CombatTracker,
  '2': QuickTools,
  '3': MusicControls,
  '4': SoundEffects,
  '5': NotesContent,
  '6': DiceRoller,
  '7': MiniMap,
  '8': MonsterDetails,
};

export function Widget({ 
  id, 
  title, 
  size, 
  position, 
  cellSize, 
  maxSize, 
  onResize, 
  onMove 
}: WidgetProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const widgetRef = useRef<HTMLDivElement>(null);
  const dragOffset = useRef({ x: 0, y: 0 });

  const scrollIntoViewIfNeeded = () => {
    if (widgetRef.current) {
      const container = document.querySelector('.dashboard-grid-container');
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const widgetRect = widgetRef.current.getBoundingClientRect();

        // Calculate if widget is out of view
        const isAbove = widgetRect.top < containerRect.top;
        const isBelow = widgetRect.bottom > containerRect.bottom;

        if (isAbove || isBelow) {
          widgetRef.current.scrollIntoView({
            behavior: 'smooth',
            block: isAbove ? 'start' : 'end'
          });
        }
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button !== 0 || !widgetRef.current) return;
    
    const rect = widgetRef.current.getBoundingClientRect();
    dragOffset.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    };
    
    setIsDragging(true);
    scrollIntoViewIfNeeded();
    e.preventDefault();
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging && !isResizing) return;
    if (!widgetRef.current) return;

    if (isDragging) {
      const newX = Math.floor((e.clientX - dragOffset.current.x) / cellSize);
      const newY = Math.floor((e.clientY - dragOffset.current.y) / cellSize);

      onMove(id, {
        x: Math.max(0, Math.min(newX, maxSize.width - size.width)),
        y: Math.max(0, Math.min(newY, maxSize.height - size.height))
      });

      scrollIntoViewIfNeeded();
    }

    if (isResizing) {
      const rect = widgetRef.current.getBoundingClientRect();
      const newWidth = Math.max(1, Math.min(Math.ceil((e.clientX - rect.left) / cellSize), maxSize.width - position.x));
      const newHeight = Math.max(1, Math.min(Math.ceil((e.clientY - rect.top) / cellSize), maxSize.height - position.y));
      onResize(id, { width: newWidth, height: newHeight });
      scrollIntoViewIfNeeded();
    }
  };

  useEffect(() => {
    if (isDragging || isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isDragging, isResizing]);

  const WidgetComponent = widgetComponents[id];

  return (
    <div
      ref={widgetRef}
      className="absolute widget-container rounded-lg overflow-hidden shadow-lg"
      style={{
        width: `${size.width * cellSize}px`,
        height: `${size.height * cellSize}px`,
        transform: `translate(${position.x * cellSize}px, ${position.y * cellSize}px)`,
        transition: isDragging || isResizing ? 'none' : 'transform 0.2s ease-out'
      }}
    >
      <div className="widget-header p-2 flex items-center justify-between cursor-move" onMouseDown={handleMouseDown}>
        <div className="flex items-center space-x-2">
          <GripVertical className="w-4 h-4 text-stone-400" />
          <h3 className="text-sm font-medium">{title}</h3>
        </div>
      </div>

      <div className="widget-content p-3 h-[calc(100%-40px)] overflow-auto">
        {WidgetComponent && <WidgetComponent />}
      </div>

      <button
        className="absolute bottom-0 right-0 p-1 text-stone-400 hover:text-stone-200 cursor-se-resize"
        onMouseDown={(e) => {
          e.stopPropagation();
          setIsResizing(true);
          scrollIntoViewIfNeeded();
        }}
      >
        <ArrowDownRight className="w-4 h-4" />
      </button>
    </div>
  );
}
import React from 'react';
import { Compass, ZoomIn, ZoomOut, Crosshair } from 'lucide-react';

export function MiniMap() {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 relative bg-stone-900/50 rounded-lg border border-stone-700 overflow-hidden">
        {/* Fantasy map content */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-full h-full bg-[url('https://images.unsplash.com/photo-1576769267415-9642010aa962?q=80&w=500&auto=format&fit=crop')] 
            bg-cover bg-center opacity-80"
          />
          <Compass className="absolute text-gold/50 w-32 h-32" />
        </div>

        {/* Map controls */}
        <div className="absolute top-2 right-2 flex flex-col gap-1">
          <button className="p-1.5 bg-stone-800/90 hover:bg-stone-700/90 rounded-lg 
            text-stone-400 hover:text-stone-200 transition-colors">
            <ZoomIn className="w-4 h-4" />
          </button>
          <button className="p-1.5 bg-stone-800/90 hover:bg-stone-700/90 rounded-lg 
            text-stone-400 hover:text-stone-200 transition-colors">
            <ZoomOut className="w-4 h-4" />
          </button>
          <button className="p-1.5 bg-stone-800/90 hover:bg-stone-700/90 rounded-lg 
            text-stone-400 hover:text-stone-200 transition-colors">
            <Crosshair className="w-4 h-4" />
          </button>
        </div>

        {/* Scale indicator */}
        <div className="absolute bottom-2 left-2 px-2 py-1 bg-stone-800/90 rounded text-xs text-stone-300">
          50 ft
        </div>
      </div>
    </div>
  );
}
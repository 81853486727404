import React, { useState } from 'react';
import { Swords, Heart, Shield, Zap, ChevronRight, Plus, Minus } from 'lucide-react';

interface Creature {
  id: number;
  name: string;
  initiative: number;
  hp: number;
  maxHp: number;
  ac: number;
  status: string;
}

export function CombatTracker() {
  const [currentTurn, setCurrentTurn] = useState(0);
  const [creatures, setCreatures] = useState<Creature[]>([
    { id: 1, name: 'Thorgrim', initiative: 18, hp: 45, maxHp: 45, ac: 18, status: 'active' },
    { id: 2, name: 'Goblin Archer', initiative: 15, hp: 12, maxHp: 20, ac: 15, status: 'wounded' },
    { id: 3, name: 'Elara', initiative: 12, hp: 28, maxHp: 32, ac: 16, status: 'active' },
    { id: 4, name: 'Hobgoblin', initiative: 8, hp: 30, maxHp: 30, ac: 17, status: 'active' },
  ]);
  const [damageAmount, setDamageAmount] = useState<{ [key: number]: string }>({});
  const [round, setRound] = useState(1);

  const handleDamage = (id: number, isHealing: boolean = false) => {
    const amount = parseInt(damageAmount[id] || '0');
    if (isNaN(amount)) return;

    setCreatures(prev => prev.map(creature => {
      if (creature.id === id) {
        const newHp = Math.min(
          Math.max(0, creature.hp + (isHealing ? amount : -amount)),
          creature.maxHp
        );
        return { ...creature, hp: newHp };
      }
      return creature;
    }));
    setDamageAmount(prev => ({ ...prev, [id]: '' }));
  };

  const nextTurn = () => {
    if (currentTurn >= creatures.length - 1) {
      setCurrentTurn(0);
      setRound(r => r + 1);
    } else {
      setCurrentTurn(t => t + 1);
    }
  };

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-4">
          <span className="text-sm font-semibold text-stone-200">Round: {round}</span>
          <span className="text-sm text-stone-400">Turn: {currentTurn + 1}/{creatures.length}</span>
        </div>
        <button 
          onClick={nextTurn}
          className="px-3 py-1.5 bg-stone-700 text-stone-200 text-sm rounded-md hover:bg-stone-600 flex items-center space-x-1"
        >
          <span>Finish Turn</span>
          <ChevronRight className="w-4 h-4" />
        </button>
      </div>

      <div className="space-y-3">
        {creatures.map((creature, index) => (
          <div 
            key={creature.id}
            className={`p-3 rounded-lg ${
              index === currentTurn 
                ? 'bg-stone-700/50 border border-gold/30' 
                : 'bg-stone-800/30 border border-stone-700'
            }`}
          >
            <div className="flex items-center justify-between mb-2">
              <span className="font-medium text-stone-200">{creature.name}</span>
              <div className="flex items-center space-x-3">
                <div className="flex items-center text-sm text-stone-300">
                  <Zap className="w-4 h-4 mr-1 text-gold" />
                  {creature.initiative}
                </div>
                <div className="flex items-center text-sm text-stone-300">
                  <Shield className="w-4 h-4 mr-1 text-stone-400" />
                  {creature.ac}
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <div className="flex-1">
                <div className="h-2 bg-stone-700 rounded-full overflow-hidden">
                  <div 
                    className="h-full bg-red-600 transition-all duration-300"
                    style={{ width: `${(creature.hp / creature.maxHp) * 100}%` }}
                  />
                </div>
                <div className="flex items-center justify-between mt-1">
                  <Heart className="w-4 h-4 text-red-500" />
                  <span className="text-sm text-stone-300">{creature.hp}/{creature.maxHp}</span>
                </div>
              </div>

              <div className="flex items-center space-x-1">
                <button
                  onClick={() => handleDamage(creature.id, true)}
                  className="p-1 hover:bg-stone-700 rounded"
                >
                  <Plus className="w-4 h-4 text-emerald-400" />
                </button>
                <input
                  type="number"
                  value={damageAmount[creature.id] || ''}
                  onChange={(e) => setDamageAmount(prev => ({ 
                    ...prev, 
                    [creature.id]: e.target.value 
                  }))}
                  className="w-16 px-2 py-1 text-sm bg-stone-800 border border-stone-600 rounded text-stone-200"
                  placeholder="Amt"
                />
                <button
                  onClick={() => handleDamage(creature.id)}
                  className="p-1 hover:bg-stone-700 rounded"
                >
                  <Minus className="w-4 h-4 text-red-400" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
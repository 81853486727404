import React, { useState } from 'react';
import { 
  Compass, Swords, Map, Music, 
  Monitor, Projector, Settings, Menu,
  Grid, LayoutGrid, Eye, LayoutDashboard
} from 'lucide-react';
import { WidgetVisibilityDropdown } from './WidgetVisibilityDropdown';
import { AppView } from '../types/navigation';

interface MenuBarProps {
  currentView: AppView;
  onNavigate: (view: AppView) => void;
  columns?: number;
  setColumns?: (columns: number) => void;
  visibleWidgets?: string[];
  setVisibleWidgets?: (widgets: string[]) => void;
}

export function MenuBar({ 
  currentView,
  onNavigate,
  columns,
  setColumns,
  visibleWidgets,
  setVisibleWidgets
}: MenuBarProps) {
  const [showWidgetMenu, setShowWidgetMenu] = useState(false);

  const menuItems = [
    { icon: LayoutDashboard, label: 'Dashboard', view: 'dashboard' as AppView },
    { icon: Compass, label: 'World Builder', view: 'worldBuilder' as AppView },
    { icon: Swords, label: 'Combat', view: 'combat' as AppView },
    { icon: Map, label: 'Maps', view: 'maps' as AppView },
    { icon: Music, label: 'Audio', view: 'audio' as AppView },
    { icon: Monitor, label: 'DM Screen', view: 'dmScreen' as AppView },
    { icon: Projector, label: 'Projector', view: 'projector' as AppView },
    { icon: Settings, label: 'Settings', view: 'settings' as AppView },
  ];

  return (
    <div className="fixed top-0 left-0 right-0 h-14 menu-bar shadow-lg z-50">
      <div className="h-full px-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Menu className="w-6 h-6 mr-4 text-gold" />
          {menuItems.map((item) => (
            <button
              key={item.view}
              className={`menu-item ${
                currentView === item.view 
                  ? 'bg-gold/20 border-gold/30 text-gold'
                  : ''
              }`}
              onClick={() => onNavigate(item.view)}
            >
              <item.icon className="w-4 h-4" />
              <span className="hidden sm:inline text-sm">{item.label}</span>
            </button>
          ))}
        </div>

        {currentView === 'dashboard' && (
          <div className="flex items-center gap-4">
            {/* Grid Controls */}
            <div className="flex items-center gap-2 px-3 py-1.5 bg-stone-800/50 rounded-lg border border-stone-700">
              <Grid className="w-4 h-4 text-stone-400" />
              <button
                onClick={() => setColumns?.(Math.max(1, (columns || 12) - 1))}
                className="w-6 h-6 flex items-center justify-center hover:bg-stone-700/50 rounded"
              >
                -
              </button>
              <span className="text-sm font-medium text-stone-200 min-w-[1.5rem] text-center">
                {columns}
              </span>
              <button
                onClick={() => setColumns?.((columns || 12) + 1)}
                className="w-6 h-6 flex items-center justify-center hover:bg-stone-700/50 rounded"
              >
                +
              </button>
            </div>

            {/* Widget Visibility Control */}
            <div className="relative">
              <button
                onClick={() => setShowWidgetMenu(!showWidgetMenu)}
                className="menu-item"
              >
                <LayoutGrid className="w-4 h-4" />
                <Eye className="w-4 h-4" />
              </button>
              {showWidgetMenu && visibleWidgets && setVisibleWidgets && (
                <WidgetVisibilityDropdown
                  visibleWidgets={visibleWidgets}
                  setVisibleWidgets={setVisibleWidgets}
                  onClose={() => setShowWidgetMenu(false)}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
import React from 'react';
import { DashboardGrid } from '../components/DashboardGrid';
import { MenuBar } from '../components/MenuBar';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { DashboardState, AppView } from '../types/navigation';

interface DashboardViewProps {
  onNavigate: (view: AppView) => void;
}

const initialDashboardState: DashboardState = {
  widgets: [
    { 
      id: '1', 
      title: 'Combat Tracker', 
      size: { width: 4, height: 8 },
      position: { x: 0, y: 0 },
      visible: true
    },
    { 
      id: '6', 
      title: 'Dice Roller', 
      size: { width: 4, height: 6 },
      position: { x: 4, y: 0 },
      visible: true
    },
    { 
      id: '3', 
      title: 'Music Controls', 
      size: { width: 4, height: 7 },
      position: { x: 8, y: 0 },
      visible: true
    },
    { 
      id: '4', 
      title: 'Sound Effects', 
      size: { width: 4, height: 7 },
      position: { x: 12, y: 0 },
      visible: true
    },
    { 
      id: '2', 
      title: 'Quick Tools', 
      size: { width: 3, height: 3 },
      position: { x: 16, y: 0 },
      visible: true
    },
    { 
      id: '7', 
      title: 'Mini Map', 
      size: { width: 4, height: 4 },
      position: { x: 16, y: 3 },
      visible: true
    },
    { 
      id: '8', 
      title: 'Monster Details', 
      size: { width: 6, height: 8 },
      position: { x: 0, y: 8 },
      visible: true
    },
    { 
      id: '5', 
      title: 'Notes', 
      size: { width: 10, height: 7 },
      position: { x: 6, y: 8 },
      visible: true
    }
  ],
  columns: 25
};

export function DashboardView({ onNavigate }: DashboardViewProps) {
  const [dashboardState, setDashboardState] = useLocalStorage<DashboardState>(
    'dashboardState',
    initialDashboardState
  );

  React.useEffect(() => {
    const anyVisible = dashboardState.widgets.some(widget => widget.visible);
    if (!anyVisible) {
      setDashboardState(initialDashboardState);
    }
  }, [dashboardState.widgets]);

  const updateWidgetState = (
    widgetId: string,
    updates: Partial<DashboardState['widgets'][0]>
  ) => {
    setDashboardState(prev => ({
      ...prev,
      widgets: prev.widgets.map(widget =>
        widget.id === widgetId ? { ...widget, ...updates } : widget
      )
    }));
  };

  const visibleWidgetIds = dashboardState.widgets
    .filter(widget => widget.visible)
    .map(widget => widget.id);

  const setVisibleWidgets = (visibleIds: string[]) => {
    setDashboardState(prev => ({
      ...prev,
      widgets: prev.widgets.map(widget => ({
        ...widget,
        visible: visibleIds.includes(widget.id)
      }))
    }));
  };

  return (
    <>
      <MenuBar 
        currentView="dashboard"
        onNavigate={onNavigate}
        columns={dashboardState.columns}
        setColumns={(columns) => setDashboardState(prev => ({ ...prev, columns }))}
        visibleWidgets={visibleWidgetIds}
        setVisibleWidgets={setVisibleWidgets}
      />
      <div className="pt-14 dashboard-grid-container">
        <DashboardGrid 
          columns={dashboardState.columns}
          widgets={dashboardState.widgets}
          onWidgetUpdate={updateWidgetState}
        />
      </div>
    </>
  );
}
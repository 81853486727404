import React from 'react';
import { useLocalStorage } from './hooks/useLocalStorage';
import { DashboardView } from './views/DashboardView';
import { WorldBuilderView } from './views/WorldBuilderView';
import { AppView } from './types/navigation';

export default function App() {
  const [currentView, setCurrentView] = useLocalStorage<AppView>('currentView', 'dashboard');
  
  const renderView = () => {
    switch (currentView) {
      case 'dashboard':
        return <DashboardView onNavigate={setCurrentView} />;
      case 'worldBuilder':
        return <WorldBuilderView onNavigate={setCurrentView} />;
      default:
        return <DashboardView onNavigate={setCurrentView} />;
    }
  };

  return (
    <div className="min-h-screen bg-stone-darkest">
      {renderView()}
    </div>
  );
}
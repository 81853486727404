import React from 'react';

export function NotesContent() {
  return (
    <div className="space-y-4 text-stone-200">
      <div className="prose prose-invert prose-sm">
        <h4 className="text-lg font-semibold mb-2 text-gold">Campaign Notes</h4>
        <p>The party has just entered the Darkwood Forest. They need to:</p>
        <ul className="list-disc pl-4 text-stone-300">
          <li>Find the ancient ruins mentioned by the old sage</li>
          <li>Locate the missing merchant caravan</li>
          <li>Investigate the strange lights reported by local hunters</li>
        </ul>
        <p className="mt-4">Important NPCs:</p>
        <ul className="list-disc pl-4 text-stone-300">
          <li>Elder Miriam - Knows about the forest's history</li>
          <li>Ranger Thorne - Can guide through dangerous areas</li>
          <li>Merchant Guild Leader - Offered reward for caravan</li>
        </ul>
        <p className="mt-4">Next Session Prep:</p>
        <ul className="list-disc pl-4 text-stone-300">
          <li>Prepare forest encounter table</li>
          <li>Design ruins layout</li>
          <li>Create merchant caravan leader personality</li>
        </ul>
      </div>
    </div>
  );
}
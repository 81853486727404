import React, { useEffect, useRef } from 'react';
import { Check } from 'lucide-react';

interface WidgetVisibilityDropdownProps {
  visibleWidgets: string[];
  setVisibleWidgets: (widgets: string[]) => void;
  onClose: () => void;
}

const AVAILABLE_WIDGETS = [
  { id: '1', title: 'Combat Tracker' },
  { id: '2', title: 'Quick Tools' },
  { id: '3', title: 'Music Controls' },
  { id: '4', title: 'Sound Effects' },
  { id: '5', title: 'Notes' },
  { id: '6', title: 'Dice Roller' },
  { id: '7', title: 'Mini Map' },
  { id: '8', title: 'Monster Details' }
];

export function WidgetVisibilityDropdown({ 
  visibleWidgets, 
  setVisibleWidgets, 
  onClose 
}: WidgetVisibilityDropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const toggleWidget = (widgetId: string) => {
    if (visibleWidgets.includes(widgetId)) {
      setVisibleWidgets(visibleWidgets.filter(id => id !== widgetId));
    } else {
      setVisibleWidgets([...visibleWidgets, widgetId]);
    }
  };

  return (
    <div
      ref={dropdownRef}
      className="absolute right-0 top-12 w-64 bg-stone-800 border border-stone-700 
        rounded-lg shadow-lg overflow-hidden z-50"
    >
      <div className="p-2 space-y-1">
        {AVAILABLE_WIDGETS.map(widget => (
          <button
            key={widget.id}
            onClick={() => toggleWidget(widget.id)}
            className="w-full flex items-center justify-between px-3 py-2 rounded-md
              hover:bg-stone-700/50 transition-colors"
          >
            <span className="text-sm text-stone-200">{widget.title}</span>
            {visibleWidgets.includes(widget.id) && (
              <Check className="w-4 h-4 text-gold" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { Widget } from './Widget';
import { Undo } from 'lucide-react';
import type { WidgetData, WidgetSize, WidgetPosition } from '../types/widget';

interface DashboardGridProps {
  columns: number;
  widgets: WidgetData[];
  onWidgetUpdate: (id: string, updates: Partial<WidgetData>) => void;
}

export function DashboardGrid({ columns, widgets, onWidgetUpdate }: DashboardGridProps) {
  const [cellSize, setCellSize] = useState(calculateCellSize());
  const [maxGridSize, setMaxGridSize] = useState({ width: columns, height: 20 });
  const [showTooltip, setShowTooltip] = useState(false);

  function calculateCellSize() {
    const availableWidth = window.innerWidth - 48; // 48px for padding
    return Math.floor(availableWidth / columns);
  }

  useEffect(() => {
    const updateGridSize = () => {
      const newCellSize = calculateCellSize();
      setCellSize(newCellSize);
      
      // Calculate visible grid dimensions
      const visibleWidth = columns; // Fixed to columns count
      const visibleHeight = Math.floor((window.innerHeight - 56) / newCellSize);
      
      // Find the maximum occupied positions
      const maxOccupiedY = Math.max(...widgets.map(w => w.position.y + w.size.height));
      
      // Set grid size with fixed width and dynamic height
      setMaxGridSize({
        width: visibleWidth,
        height: Math.max(visibleHeight, maxOccupiedY + 2)
      });
    };

    updateGridSize();
    window.addEventListener('resize', updateGridSize);
    return () => window.removeEventListener('resize', updateGridSize);
  }, [columns, widgets]);

  const resetWidgetPositions = () => {
    let currentX = 0;
    let currentY = 0;
    
    widgets.forEach((widget, index) => {
      // If widget would overflow current row, move to next row
      if (currentX + widget.size.width > columns) {
        currentX = 0;
        currentY = Math.max(...widgets
          .filter((_, i) => i < index)
          .map(w => w.position.y + w.size.height));
      }
      
      // Ensure widget stays within horizontal bounds
      const newX = Math.min(currentX, columns - widget.size.width);
      
      onWidgetUpdate(widget.id, {
        position: { x: newX, y: currentY }
      });
      
      currentX += widget.size.width;
    });
  };

  const visibleWidgets = widgets.filter(widget => widget.visible);

  return (
    <div className="relative min-h-screen bg-stone-darker">
      {/* Reset button with tooltip */}
      <div className="fixed bottom-4 right-4 z-50">
        <button
          onClick={resetWidgetPositions}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          className="p-3 bg-stone-800 hover:bg-stone-700 
            text-stone-200 rounded-full shadow-lg border border-stone-600
            transition-all duration-200 hover:scale-105"
        >
          <Undo className="w-5 h-5" />
        </button>
        
        {/* Tooltip */}
        <div
          className={`absolute bottom-full right-0 mb-2 px-3 py-2 bg-stone-800 
            text-stone-200 text-sm rounded-lg shadow-lg border border-stone-600
            transition-opacity duration-200 whitespace-nowrap
            ${showTooltip ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        >
          Reset widget positions to grid
        </div>
      </div>

      {/* Grid container */}
      <div 
        className="relative mx-auto"
        style={{
          width: `${maxGridSize.width * cellSize}px`,
          height: `${maxGridSize.height * cellSize}px`,
          backgroundImage: `
            linear-gradient(to right, rgba(87, 83, 78, 0.1) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(87, 83, 78, 0.1) 1px, transparent 1px)
          `,
          backgroundSize: `${cellSize}px ${cellSize}px`,
        }}
      >
        {visibleWidgets.map(widget => (
          <Widget
            key={widget.id}
            {...widget}
            cellSize={cellSize}
            maxSize={maxGridSize}
            onResize={(id, newSize) => {
              // Prevent resizing beyond grid width
              const constrainedSize = {
                ...newSize,
                width: Math.min(newSize.width, columns - widget.position.x)
              };
              onWidgetUpdate(id, { size: constrainedSize });
            }}
            onMove={(id, newPosition) => {
              // Prevent moving beyond grid width
              const constrainedPosition = {
                ...newPosition,
                x: Math.min(Math.max(0, newPosition.x), columns - widget.size.width)
              };
              onWidgetUpdate(id, { position: constrainedPosition });
            }}
          />
        ))}
      </div>
    </div>
  );
}
import React from 'react';
import { Play, Pause, SkipBack, SkipForward, Volume2, Music, Repeat, Shuffle } from 'lucide-react';

export function MusicControls() {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-auto">
        <div className="space-y-2 mb-4">
          <div className="p-2 bg-stone-800/50 border border-stone-700 rounded-md hover:bg-stone-700/50 cursor-pointer">
            <div className="flex items-center justify-between">
              <div>
                <span className="text-sm font-medium block text-stone-200">Epic Battle Theme</span>
                <span className="text-xs text-stone-400">Fantasy Orchestra</span>
              </div>
              <Music className="w-4 h-4 text-stone-400" />
            </div>
          </div>
          <div className="p-2 bg-stone-800/50 border border-stone-700 rounded-md hover:bg-stone-700/50 cursor-pointer">
            <div className="flex items-center justify-between">
              <div>
                <span className="text-sm font-medium block text-stone-200">Tavern Ambience</span>
                <span className="text-xs text-stone-400">Medieval Atmosphere</span>
              </div>
              <Music className="w-4 h-4 text-stone-400" />
            </div>
          </div>
          <div className="p-2 bg-stone-800/50 border border-stone-700 rounded-md hover:bg-stone-700/50 cursor-pointer">
            <div className="flex items-center justify-between">
              <div>
                <span className="text-sm font-medium block text-stone-200">Forest Night</span>
                <span className="text-xs text-stone-400">Nature Sounds</span>
              </div>
              <Music className="w-4 h-4 text-stone-400" />
            </div>
          </div>
        </div>
      </div>
      
      <div className="border-t border-stone-700 pt-2">
        <div className="text-center mb-2">
          <div className="text-sm font-medium text-stone-200">Epic Battle Theme</div>
          <div className="text-xs text-stone-400">Fantasy Orchestra</div>
        </div>
        
        <div className="flex justify-center items-center space-x-4 mb-3">
          <button className="p-1 hover:bg-stone-700/50 rounded-full text-stone-400 hover:text-stone-200">
            <Shuffle className="w-4 h-4" />
          </button>
          <button className="p-1 hover:bg-stone-700/50 rounded-full text-stone-400 hover:text-stone-200">
            <SkipBack className="w-5 h-5" />
          </button>
          <button className="p-2 bg-gold/20 hover:bg-gold/30 rounded-full border border-gold/30">
            <Play className="w-6 h-6 text-gold" />
          </button>
          <button className="p-1 hover:bg-stone-700/50 rounded-full text-stone-400 hover:text-stone-200">
            <SkipForward className="w-5 h-5" />
          </button>
          <button className="p-1 hover:bg-stone-700/50 rounded-full text-stone-400 hover:text-stone-200">
            <Repeat className="w-4 h-4" />
          </button>
        </div>
        
        <div className="flex items-center space-x-2">
          <Volume2 className="w-4 h-4 text-stone-400" />
          <input
            type="range"
            className="flex-1 h-1 bg-stone-700 rounded-lg appearance-none cursor-pointer"
            min="0"
            max="100"
            defaultValue="75"
          />
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { Shield, Heart, Sword, Zap, Brain } from 'lucide-react';

export function MonsterDetails() {
  return (
    <div className="flex flex-col h-full space-y-4">
      <div className="flex space-x-4">
        {/* Monster Image */}
        <div className="w-24 h-24 rounded-lg overflow-hidden border border-stone-700">
          <img 
            src="https://images.unsplash.com/photo-1590684874951-c482687d0aba?w=200&q=80" 
            alt="Ancient Dragon"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Basic Stats */}
        <div className="flex-1">
          <h3 className="text-lg font-bold text-gold mb-1">Ancient Red Dragon</h3>
          <div className="text-sm text-stone-400">Gargantuan dragon, chaotic evil</div>
          <div className="flex space-x-4 mt-2">
            <div className="flex items-center text-stone-300">
              <Shield className="w-4 h-4 mr-1 text-stone-400" />
              <span>19</span>
            </div>
            <div className="flex items-center text-stone-300">
              <Heart className="w-4 h-4 mr-1 text-red-400" />
              <span>256/256</span>
            </div>
            <div className="flex items-center text-stone-300">
              <Zap className="w-4 h-4 mr-1 text-gold" />
              <span>22</span>
            </div>
          </div>
        </div>
      </div>

      {/* Abilities */}
      <div className="grid grid-cols-6 gap-2 p-2 bg-stone-800/30 rounded-lg border border-stone-700">
        <div className="text-center">
          <div className="text-sm font-medium text-stone-300">STR</div>
          <div className="text-lg text-gold">26</div>
          <div className="text-xs text-stone-400">+8</div>
        </div>
        <div className="text-center">
          <div className="text-sm font-medium text-stone-300">DEX</div>
          <div className="text-lg text-gold">14</div>
          <div className="text-xs text-stone-400">+2</div>
        </div>
        <div className="text-center">
          <div className="text-sm font-medium text-stone-300">CON</div>
          <div className="text-lg text-gold">24</div>
          <div className="text-xs text-stone-400">+7</div>
        </div>
        <div className="text-center">
          <div className="text-sm font-medium text-stone-300">INT</div>
          <div className="text-lg text-gold">16</div>
          <div className="text-xs text-stone-400">+3</div>
        </div>
        <div className="text-center">
          <div className="text-sm font-medium text-stone-300">WIS</div>
          <div className="text-lg text-gold">15</div>
          <div className="text-xs text-stone-400">+2</div>
        </div>
        <div className="text-center">
          <div className="text-sm font-medium text-stone-300">CHA</div>
          <div className="text-lg text-gold">19</div>
          <div className="text-xs text-stone-400">+4</div>
        </div>
      </div>

      {/* Actions */}
      <div className="flex-1 overflow-auto">
        <div className="space-y-3">
          <div className="p-2 bg-stone-800/30 rounded-lg border border-stone-700">
            <h4 className="text-sm font-semibold text-gold flex items-center">
              <Sword className="w-4 h-4 mr-1" />
              Multiattack
            </h4>
            <p className="text-sm text-stone-300 mt-1">
              The dragon can use its Frightful Presence. It then makes three attacks: one with its bite and two with its claws.
            </p>
          </div>

          <div className="p-2 bg-stone-800/30 rounded-lg border border-stone-700">
            <h4 className="text-sm font-semibold text-gold flex items-center">
              <Brain className="w-4 h-4 mr-1" />
              Fire Breath (Recharge 5-6)
            </h4>
            <p className="text-sm text-stone-300 mt-1">
              The dragon exhales fire in a 60-foot cone. Each creature in that area must make a DC 21 Dexterity saving throw, taking 63 (18d6) fire damage on a failed save, or half as much damage on a successful one.
            </p>
          </div>

          <div className="p-2 bg-stone-800/30 rounded-lg border border-stone-700">
            <h4 className="text-sm font-semibold text-gold flex items-center">
              <Sword className="w-4 h-4 mr-1" />
              Bite
            </h4>
            <p className="text-sm text-stone-300 mt-1">
              Melee Weapon Attack: +14 to hit, reach 10 ft., one target. Hit: 19 (2d10 + 8) piercing damage plus 7 (2d6) fire damage.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { Volume2, Play } from 'lucide-react';

const soundEffects = [
  { name: 'Sword Clash', category: 'Combat' },
  { name: 'Fireball Explosion', category: 'Magic' },
  { name: 'Thunder Crack', category: 'Weather' },
  { name: 'Door Creak', category: 'Environment' },
  { name: 'Footsteps on Wood', category: 'Movement' },
  { name: 'Dragon Roar', category: 'Creatures' },
  { name: 'Magic Spell', category: 'Magic' },
  { name: 'Wolf Howl', category: 'Creatures' },
  { name: 'Rain Ambience', category: 'Weather' },
  { name: 'Chest Opening', category: 'Environment' },
  { name: 'Sword Drawing', category: 'Combat' },
  { name: 'Fire Crackle', category: 'Environment' },
  { name: 'Monster Growl', category: 'Creatures' },
  { name: 'Chain Rattle', category: 'Environment' },
  { name: 'Magic Chimes', category: 'Magic' }
];

export function SoundEffects() {
  return (
    <div className="flex flex-col h-full space-y-4">
      <div className="flex items-center space-x-2 p-2 bg-stone-800/50 border border-stone-700 rounded-md">
        <input
          type="text"
          placeholder="Search effects..."
          className="flex-1 px-3 py-1 rounded bg-stone-900 border-0 text-stone-200 placeholder-stone-500
            focus:ring-2 focus:ring-gold/50"
        />
      </div>

      <div className="flex-1 overflow-auto">
        <div className="grid gap-2">
          {soundEffects.map((effect, index) => (
            <div
              key={index}
              className="p-2 bg-stone-800/30 border border-stone-700 rounded-md 
                hover:bg-stone-700/30 transition-colors"
            >
              <div className="flex items-center justify-between">
                <div>
                  <div className="text-sm font-medium text-stone-200">{effect.name}</div>
                  <div className="text-xs text-stone-400">{effect.category}</div>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-1">
                    <Volume2 className="w-3 h-3 text-stone-400" />
                    <input
                      type="range"
                      className="w-16 h-1 bg-stone-700 rounded-lg appearance-none cursor-pointer"
                      min="0"
                      max="100"
                      defaultValue="75"
                    />
                  </div>
                  <button className="p-1 hover:bg-stone-700 rounded-full text-stone-400 hover:text-stone-200">
                    <Play className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import { 
  Triangle, Square, Pentagon, Hexagon, Circle, Plus, History
} from 'lucide-react';

type DiceType = 'd4' | 'd6' | 'd8' | 'd10' | 'd12' | 'd20' | 'd100';

interface DiceRoll {
  type: DiceType;
  result: number;
  timestamp: number;
}

const diceConfig = [
  { type: 'd4', icon: Triangle, color: 'text-emerald-400', bgColor: 'bg-emerald-900/30' },
  { type: 'd6', icon: Square, color: 'text-blue-400', bgColor: 'bg-blue-900/30' },
  { type: 'd8', icon: Pentagon, color: 'text-purple-400', bgColor: 'bg-purple-900/30' },
  { type: 'd10', icon: Circle, color: 'text-amber-400', bgColor: 'bg-amber-900/30' },
  { type: 'd12', icon: Hexagon, color: 'text-red-400', bgColor: 'bg-red-900/30' },
  { type: 'd20', icon: Pentagon, color: 'text-indigo-400', bgColor: 'bg-indigo-900/30' },
  { type: 'd100', icon: Circle, color: 'text-teal-400', bgColor: 'bg-teal-900/30' }
];

export function DiceRoller() {
  const [rolls, setRolls] = useState<DiceRoll[]>([]);
  const [selectedDice, setSelectedDice] = useState<DiceType>('d20');
  const [quantity, setQuantity] = useState(1);
  const [modifier, setModifier] = useState(0);

  const rollDice = () => {
    const results: DiceRoll[] = [];
    const sides = parseInt(selectedDice.substring(1));
    
    for (let i = 0; i < quantity; i++) {
      results.push({
        type: selectedDice,
        result: Math.floor(Math.random() * sides) + 1,
        timestamp: Date.now() + i
      });
    }
    
    setRolls([...results, ...rolls].slice(0, 10));
  };

  const getTotal = (rolls: DiceRoll[]) => {
    return rolls.slice(0, quantity).reduce((sum, roll) => sum + roll.result, 0) + modifier;
  };

  const getDiceIcon = (type: DiceType) => {
    const config = diceConfig.find(d => d.type === type);
    const Icon = config?.icon || Square;
    return <Icon className={`w-5 h-5 ${config?.color}`} />;
  };

  return (
    <div className="flex flex-col h-full">
      {/* Dice Selection Grid */}
      <div className="grid grid-cols-4 gap-1.5 mb-3">
        {diceConfig.map(({ type, color, bgColor }) => (
          <button
            key={type}
            onClick={() => setSelectedDice(type)}
            className={`p-2 rounded-lg flex flex-col items-center justify-center transition-all
              ${selectedDice === type 
                ? `ring-2 ring-gold/50 ${bgColor}` 
                : 'bg-stone-800/30 hover:bg-stone-700/30'}`}
          >
            {getDiceIcon(type)}
            <span className={`text-xs mt-1 font-medium ${selectedDice === type ? color : 'text-stone-400'}`}>
              {type}
            </span>
          </button>
        ))}
      </div>

      {/* Roll Controls */}
      <div className="flex space-x-2 mb-3">
        <div className="flex-1 flex space-x-2">
          <div className="relative">
            <input
              type="number"
              min="1"
              max="10"
              value={quantity}
              onChange={(e) => setQuantity(Math.min(10, Math.max(1, parseInt(e.target.value) || 1)))}
              className="w-16 px-2 py-1.5 rounded bg-stone-800 border border-stone-600 text-stone-200 text-sm"
              placeholder="Qty"
            />
            <span className="absolute -top-2 left-2 text-xs bg-stone-900 px-1 text-stone-400">
              Quantity
            </span>
          </div>
          <div className="relative flex items-center space-x-1">
            <Plus className="w-4 h-4 text-stone-400" />
            <input
              type="number"
              value={modifier}
              onChange={(e) => setModifier(parseInt(e.target.value) || 0)}
              className="w-16 px-2 py-1.5 rounded bg-stone-800 border border-stone-600 text-stone-200 text-sm"
              placeholder="Mod"
            />
            <span className="absolute -top-2 left-6 text-xs bg-stone-900 px-1 text-stone-400">
              Modifier
            </span>
          </div>
        </div>
        <button
          onClick={rollDice}
          className="px-4 py-1.5 bg-gold/20 text-gold hover:bg-gold/30 rounded-lg 
            text-sm font-medium flex items-center justify-center space-x-2 min-w-[80px]
            border border-gold/30"
        >
          <span>Roll</span>
          {getDiceIcon(selectedDice)}
        </button>
      </div>

      {/* Results Area */}
      <div className="flex-1 overflow-auto">
        {rolls.length > 0 && (
          <div className="space-y-2">
            {/* Total Display */}
            <div className="p-3 bg-stone-800/50 border border-stone-700 text-stone-200 rounded-lg">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <span className="text-sm">Total Roll</span>
                  {modifier !== 0 && (
                    <span className="text-xs text-stone-400">
                      ({quantity}d{selectedDice.substring(1)} {modifier > 0 ? '+' : ''}{modifier})
                    </span>
                  )}
                </div>
                <span className="text-lg font-bold text-gold">{getTotal(rolls)}</span>
              </div>
            </div>

            {/* Individual Rolls */}
            <div className="space-y-1.5">
              <div className="flex items-center space-x-2 text-xs text-stone-400 px-2">
                <History className="w-4 h-4" />
                <span>Recent Rolls</span>
              </div>
              {rolls.slice(0, quantity).map((roll, index) => (
                <div
                  key={roll.timestamp}
                  className={`p-2 rounded-lg flex items-center justify-between
                    ${index === 0 
                      ? `${diceConfig.find(d => d.type === roll.type)?.bgColor} border border-stone-700` 
                      : 'bg-stone-800/30 border border-stone-700'}`}
                >
                  <div className="flex items-center space-x-2">
                    {getDiceIcon(roll.type)}
                    <span className="text-sm font-medium text-stone-300">
                      {roll.type}
                    </span>
                  </div>
                  <span className={`text-sm font-bold ${
                    roll.result === parseInt(roll.type.substring(1)) ? 'text-emerald-400' : 
                    roll.result === 1 ? 'text-red-400' : 'text-stone-200'
                  }`}>
                    {roll.result}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
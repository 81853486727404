import React from 'react';
import { MenuBar } from '../components/MenuBar';
import { AppView } from '../types/navigation';

interface WorldBuilderViewProps {
  onNavigate: (view: AppView) => void;
}

export function WorldBuilderView({ onNavigate }: WorldBuilderViewProps) {
  return (
    <>
      <MenuBar 
        currentView="worldBuilder"
        onNavigate={onNavigate}
      />
      <div className="pt-14 p-8">
        <h1 className="text-4xl font-bold text-gold mb-4">World Builder</h1>
        <p className="text-stone-300">World Builder interface will be implemented here.</p>
      </div>
    </>
  );
}